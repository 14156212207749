:root {
    --cool-grey: #8d9ba6;
    --black: #101010;
    --black-three: #141414;
    --dark-seafoam-green: #3bb278;
    --black-two: #131313;
    --slate-grey: #5a6872;
    --dodger-blue: #2f9cf5;
    --pale-grey: #dee6ea;
    --white: #ffffff;
    --dark-mint: #46c486;
    --cool-grey-two: #a3afb8;
    --pale-grey-two: #dfe6ea;
    --pine: #387758;
    --battleship-grey: #6c727b;
    --dark-slate-blue: #252e73;
    --ice-blue: #fafdff;
    --light-sky-blue: #d3ebff;
    --ice: #e8fff4;
    --cloudy-blue: #d4dde5;
    --cloudy-blue-10: #e7eef5;
    --water-blue: #1a82d7;
    --greyblue: #8dabc4;
    --light-blue-grey: #a8c6df;
    --deep-sky-blue: #007fff;
    --charcoal-grey: #323a3e;
    --black-four: #111111;
    --slate-grey-two: #585f73;
    --dark: #0d101a;
    --off-white: #f5faf4;
    --algae-green-50: rgba(31, 187, 100, 0.5);
    --very-light-pink-0: rgba(216, 216, 216, 0);
    --slate: #566973;
    --cool-grey-three: #979fa5;
    --pale-grey-five: #f8fbff;
    --white-three:#d8d8d8;
    --algae-green: #1fbb64;
    --light-sky-blue-two: #d9e3ed;
    --pale-red: #dd4747;

}
