.ant-input{
    font-family: SFProDisplay-Medium;
    font-size: 15px;

    background-color: var(--white);
    height: 32px;
}
.ant-input-affix-wrapper{
    border-radius: 5px;
    border: solid 1px var(--cloudy-blue);
}

.ant-input-number{
    width: 100%;
    height: 42px;
    padding-top: 4px;
    border-radius: 5px;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector{
    font-family: SFProDisplay-Medium;
    font-size: 15px;

    background-color: var(--white);
    height: 42px;
    border-radius: 5px;
    width: 100%;
}
.ant-picker{
    font-family: SFProDisplay-Medium;
    font-size: 15px;
    background-color: var(--white);
    height: 42px;
    border-radius: 5px;
    width: 100%;
}